import { createAction } from '@reduxjs/toolkit';

export const BUTTON_CLICKED = 'app/BUTTON_CLICKED';

export const buttonClicked = createAction<
  | ButtonClickedOnPost
  | ButtonClickedAction
  | ButtonClickedLoginToComment
  | ButtonClickedBadges
  | ButtonClickedImageExpand
  | ButtonClickedWhoLiked
  | ButtonClickedReactionsCount
  | ButtonClickedMarkAsBestAnswer
  | ButtonClickedToggleSuggestions
  | ButtonClickedMarkAllAsRead
>(BUTTON_CLICKED);

interface ButtonClickedBase {
  name: string;
}

export interface ButtonClickedOnPost extends ButtonClickedBase {
  _id: string;
  title: string;
  slug: string;
  term: string;
  origin: string;
  isRead: boolean;
}

export interface ButtonClickedAction extends ButtonClickedBase {
  categoryId: string;
  postId: string;
  type: string;
  action: string;
  flag?: number;
}

export interface ButtonClickedLoginToComment extends ButtonClickedBase {
  type: string;
}

export interface ButtonClickedBadges extends ButtonClickedBase {
  name: string;
  id: string;
}

export interface ButtonClickedImageExpand extends ButtonClickedBase {
  origin: string;
}

export interface ButtonClickedWhoLiked extends ButtonClickedBase {
  type: string;
}

export interface ButtonClickedReactionsCount extends ButtonClickedBase {
  reactionType: 'likes' | 'emotions';
  postId: string;
}

export interface ButtonClickedMarkAsBestAnswer extends ButtonClickedBase {
  isMarked: boolean;
  origin: string;
  postId: string;
  commentId: string;
}

export interface ButtonClickedToggleSuggestions extends ButtonClickedBase {
  isEnabled: boolean;
  categoryId: string;
}

export interface ButtonClickedMarkAllAsRead extends ButtonClickedBase {
  action: 'mark_all_as_read';
  categoryId: string;
}
